// Step 1: Import React
import * as React from "react";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import { Grid, Row, Col, Cell } from "react-styled-grid-layout";
import { StaticImage } from "gatsby-plugin-image";
import TypeIt from "typeit-react";
import { AiOutlineUnorderedList, AiOutlineComment } from "react-icons/ai";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Pricingtable from "../../components/pricingtable";
import { GiPlanetConquest } from "react-icons/gi";

const SuperStrong = ({ children }) => {
  return (
    <strong style={{ fontSize: "45px", fontFamily: "Noto Sans KR" }}>
      {children}
    </strong>
  );
};

// Step 2: Define your component
const AboutPage = () => {
  return (
    <Layout pageTitle="컨설팅">
        {/*  
      <Link to="/service/betatester" className="cta">
        무료체험 이벤트신청
      </Link>
*/}
      <div className="Blank"></div>
      <div className="top">
        <center>
          <h1>
            <TypeIt element={"h1"}>
              우리가 <SuperStrong>컨설팅</SuperStrong>에서 일을 시작하는 이유
            </TypeIt>
          </h1>
        </center>
      </div>

      <div className="Blank"></div>


      <Grid cols="12" gap="10px">
          
<Col xxl="4" md="6" sm="12">
          <div>
            <h2>
              <TypeIt
                options={{
                  waitUntilVisible: true,
                }}
              >
                안녕하세요, 한의사 최연승입니다.
              </TypeIt>
            </h2>
          </div>
        </Col>
        <Col xxl="8" md="6" sm="12">
      <center>
                    <StaticImage
                      src="./images/profile_with_resume.png"
                      width={450}
                    />{" "}
                  </center>

           <div
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <p>
              모든마케팅은 한의사 최연승이 직접 기획하고 운영하는 전문직 대상 마케팅대행사입니다.
              병원을 운영하면서 겪게 되는 다양한 고충과 경험을 바탕으로 사업주의 관점에서 마케팅을 바라봅니다.
            </p>
          </div>
    

         <div className="Blank"></div>
        </Col>




      <Col xxl="4" md="6" sm="12">
          <div>
            <h2>
              <TypeIt
                options={{
                  waitUntilVisible: true,
                }}
              >
                무엇이 차이를 만들까?
              </TypeIt>
            </h2>
          </div>
        </Col>
        <Col xxl="8" md="6" sm="12">
          <div
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
              <p>
                  저는 직접 병원을 운영해오면서 여러번 시행착오를 거쳐가며 본 서비스를 만들어왔습니다. 
                  제가 기존 마케팅대행 구조에서 아쉽다고 느끼던 부분들을 해결하고자 만든 서비스로, 
                  누구보다 사업주의 관점을 잘 이해하고 있다고 생각합니다. 
              </p>
              <p>
                  그 결과 다양한 클라이언트를 모시고 마케팅 대행을 수행해왔습니다. 
                  일부는 몇 년간 우리 서비스를 애용하며 상당한 성과를 거둔 반면, 
                  다른 일부는 동일한 전략을 사용하더라도 기대했던 성과를 이루지 못한 경우도 있었습니다. 
                  일련의 경험들을 통해 저는 기존 마케팅 방식의 한계를 더 깊이 이해하게 되었습니다. 
              </p>
              <p>
                  <b>왜 이러한 차이가 발생하는 것일까요?</b>
              </p>
              <p>
                  마케팅은 마치 그릇에 물을 부어주는 것과 같습니다. 
                  그러나 그릇이 깨져있다면 아무리 효과적인 마케팅이라도 실질적인 매출로 이어지기 어려워집니다.
              </p>
              <p>
                  <span className = "HL">초기 마케팅 셋업</span>은 그릇을 만드는 과정입니다. 
                  그리고 이 과정은 컨설팅 형식이 아니고서는 충분히 전달이 불가능하다는 결론을 내렸습니다.
                              </p>
          </div>
          <div className="Blank"></div>
        </Col>
        <Col xxl="4" md="6" sm="12">
          <div>
            <h2>
              <TypeIt
                options={{
                  waitUntilVisible: true,
                }}
              >
                이것을 목표로 합니다
              </TypeIt>
            </h2>
          </div>
        </Col>

        <Col xxl="8" md="6" sm="12">
      
      <div
            className="tablecell"
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease"
      >

      <center><StaticImage src="./images/graph.png" />{" "}</center>
                        <p>
              마케팅을 통해 매출이 발생하기 위해서는 그릇을 만들기 위한 최소한의 전처리 작업이 필수입니다.
            </p>

            <div className="details"></div>
            <div className="details">
              <details>
                <summary className="buttontypetext">
                  &nbsp;고객정의
                </summary>
                <p className="subdetails">
                  우리의 고객이 누구인지 명확하게 정의합니다.
                </p>
              </details>
            </div>
            <div className="details">
              <details>
                <summary className="buttontypetext">
                  &nbsp;프로덕트정의
                </summary>
                <p className="subdetails">
                    제품이나 서비스를 정의하고 특징을 강조하여 뚜렷한 가치 제안을 구체적으로 제시해야 합니다.
                </p>
              </details>
            </div>
            <div className="details">
              <details>
                <summary className="buttontypetext">
                  &nbsp;프라이싱 설계
                </summary>
                <p className="subdetails">
                    효과적인 프라이싱 전략을 수립합니다. 가격은 제품의 가치를 반영하며 시장과 경쟁 환경을 고려해야 합니다.
                </p>
              </details>
          </div>
        <div className="details">
              <details>
                <summary className="buttontypetext">
                  &nbsp;비가시적인 요소의 시각화
                </summary>
                <p className="subdetails">
                    브랜드의 가치와 이미지를 강화하기 위해 비가시적인 부분도 시각적으로 표현합니다. 로고, 색상, 디자인 등을 활용하여 브랜드를 강조합니다.
                </p>
              </details>
            </div>
            <p>전처리작업이 마무리되면 가용 가능한 예산을 기준으로 가장 효과가 높을 것으로 판단되는 마케팅 채널을 선별하여 실행전략을 세워야합니다.
            </p><p>
            
            이 과정을 통해 마케팅에 대한 이해가 깊어지고, 더 나아가 비지니스를 이해하는 시각이 발전하게 됩니다. 이를 토대로 스스로 전략을 수립하고 실행하는 데 필요한 의사결정 프레임워크를 구축할 수 있습니다.
        </p>
          </div>
          <div className="Blank"></div>
        </Col>



        <Col xxl="4" md="6" sm="12">
          <div>
            <h2>
              <TypeIt
                options={{
                  waitUntilVisible: true,
                }}
              >
                이런 내용을 다룹니다
              </TypeIt>
            </h2>
          </div>
        </Col>
        <Col xxl="8" md="6" sm="12">
          <div
            className="tablecell"
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
              <p>강의 형식으로는 다루지 못하는 경험과 노하우를 전달합니다. 

          </p>

        <p>
              세션 1 (3hr)</p>
<ul>
                        <li> 비지니스 모델 현황분석</li>
                        <li> 마케팅 현황분석</li>
                        <li> 시장조사</li>
                        <li> 경쟁업체 분석</li>
                    </ul>
<p>
              세션 2 (3hr)</p>
<ul>
        <li> 클리닉 셋업 (백엔드)</li>
                        <li> 브랜딩 및 퍼널 설계 (프론트엔드)</li>
                        <li> 마케팅 전략 실행 로드맵 수립</li>
                    </ul>



                    <p>

분석을 바탕으로 전략을 도출하고 실행 가능한 옵션을 우선순위에 따라 제시합니다.

즉각적인 매출 증가를 도와주는 실행 가능한 방안들을 제안하며,

현실적이면서도 실행 가능한 방안들을 제안하고 바로 실행할 수 있도록 동기부여합니다.
                        </p>
                        <p>
                        마케팅 실행은 컨설팅과 연계하여 모든마케팅과  함께 진행할 수도 있지만, 우리가 직접 하지 않는 방식이라면 적절한 외주 옵션을 선택할 수 있도록 가이드라인을 제안합니다.

            </p><p>
            
        </p>
          </div>
          <div className="Blank"></div>
        </Col>


<Col xxl="4" md="6" sm="12">
          <div>
            <h2>
              <TypeIt
                options={{
                  waitUntilVisible: true,
                }}
              >
                이렇게 진행됩니다.
              </TypeIt>
            </h2>
          </div>
        </Col>
        <Col xxl="8" md="6" sm="12">
          <div
            className="tablecell"
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
              <p>
              컨설팅 프로세스는 아래와 같이 진행됩니다.
              </p>

            <div className="details">
              <details>
                <summary className="buttontypetext">
                  &nbsp;구조화된 설문 작성
                </summary>
                <p className="subdetails">
                세션을 효율적으로 진행하기 위해 사전에 구조화된 설문을 작성합니다.
                </p>
              </details>
            </div>
            <div className="details">
              <details>
                <summary className="buttontypetext">
                  &nbsp;사전인터뷰 (30분)
                </summary>
                <p className="subdetails">
                수집한 자료를 기반으로 30분 동안 클라이언트의 니즈와 컨설팅 방향성을 논의합니다. 이는 줌을 통해 진행됩니다.
                </p>
              </details>
            </div>
            <div className="details">
              <details>
                <summary className="buttontypetext">
                  &nbsp; 메인 컨설팅 세션 (2회)
                </summary>
                <p className="subdetails">
                    본격적인 컨설팅은 2세션으로 나누어 3시간씩 진행됩니다. 이는 기본적으로 하루에 진행되며, 스케쥴에 따라 조정될 수 있습니다.
                </p>
              </details>
          </div>
        <div className="details">
              <details>
                <summary className="buttontypetext">
                  &nbsp;피드백 세션 (1시간, 필요시)                 </summary>
              <p className="subdetails">
필요에 따라 추가로 1시간짜리 피드백 세션을 진행할 수 있습니다.

                </p>
              </details>
            </div>
            
          </div>
          <div className="Blank"></div>
        </Col>


<Col xxl="4" md="6" sm="12">
          <div>
            <h2>
              <TypeIt
                options={{
                  waitUntilVisible: true,
                }}
              >
                비용안내
              </TypeIt>
            </h2>
          </div>
        </Col>
        <Col xxl="8" md="6" sm="12">
          <div
            className="tablecell"
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
              <p>
              메인 세션은 300만원이며, VAT는 별도입니다.

              </p>

            <div className="details">
              <details>
                <summary className="buttontypetext">
                  &nbsp;시간당비용
                </summary>
                <p className="subdetails">
                    컨설팅 형식으로 진행되는 세션은 시간당 50만원의 비용이 발생합니다. 1:1 코칭으로 진행됩니다.
                </p>
              </details>
            </div>
            <div className="details">
              <details>
                <summary className="buttontypetext">
                  &nbsp;할인정책
                </summary>
                <p className="subdetails">
                컨설팅 단독 상품은 별도의 할인이 제공되지 않습니다. 그러나 마케팅대행 장기 계약시 계약조건에 따라 컨설팅 비용이 할인 적용되니 해당 서비스를 참고해주세요.
                </p>
              </details>
            </div>
            <div className="details">
              <details>
                <summary className="buttontypetext">
                  &nbsp; 환불 정책
                </summary>
                <p className="subdetails">
                사전인터뷰에서 진행 여부를 결정한 후, 해당 과정이 도움이 되지 않을 것으로 판단되면 본 세션은 진행되지 않으며, 30분의 인터뷰 비용만 책정되어 환불 처리됩니다. 별도의 사유가 없는한 컨설팅이 진행되는 중도에는 환불이 되지 않습니다.
                </p>
              </details>
          </div>
        
          </div>
          <div className="Blank"></div>
        </Col>

<Col xxl="4" md="6" sm="12">
          <div>
            <h2>
              <TypeIt
                options={{
                  waitUntilVisible: true,
                }}
              >
신중하게 결정해주세요.               </TypeIt>
            </h2>
          </div>
        </Col>
        <Col xxl="8" md="6" sm="12">
          <div
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
              <p>본 컨설팅 과정은 일방향 강의가 아닙니다.
          </p>
          <p>
사실 컨설팅 방식은 당사자 양방이 모두 만족하기 어려운 경우가 많습니다.

              </p> <p>
컨설팅을 해주는 사람의 입장에서는 1:1로 리소스를 투여해 경험과 노하우를 전달하기 때문에 단순지식을 전달하는 강의 형식보다 고가를 책정할 수 밖에 없습니다. 
              </p><p>
              반면 컨설팅을 받는 사람의 입장에서는 시간당비용으로 상당한 액수를 지불해야 하므로 비용 이상의 효용감을 느낄 수 있어야 합니다. 
              </p><p>
              컨설팅 방식은 누구에게나 적합한 방식은 아니지만 나름의 각오와 준비가 되어있는 분들께는 도움이 되실 것이라 확신합니다.
          </p><p>
다만, 컨설팅은 실행전략 수립에서 마무리되므로 실행으로 이어지지 않으면 크게 도움이 되지 않을 수 있습니다. 

이 부분을 염두에 두고 신청해주시기바라며, 

컨설팅 단독 서비스보다는 컨설팅과 마케팅 대행을 결합한 할인 적용 서비스를 추천드립니다.</p>    

                  </div>
          <div className="Blank"></div>
        </Col>

      </Grid>
      {/*
      <div className="divmargin">
        <div>
          <h2>더 궁금한 점이 있다면?</h2>

          <p>
            먼저 FAQ 페이지에서 해당 내용을 찾아보세요. 그래도 궁금증이 해소되지
            않는다면 문의하기 메뉴를 활용해주세요.
          </p>

          <div>
            <Link to="/service/faq" target="_blank">
              <button className="button">&nbsp;&nbsp;자주 묻는 질문 </button>
            </Link>
          </div>
        </div>
    </div>
*/}

      <div>
        <h2 id="contact">
          <div className="icon">
            <GiPlanetConquest />
          </div>{" "}
          Contact Us.
        </h2>
        <div>
          <p>
            효율적인 커뮤니케이션을 위해 비대면 응대를 원칙으로 하고 있습니다.
            궁금한 점이 있으시다면 아래 내용을 남겨주세요. 최대한 빠르게 답변
            드리겠습니다.
          </p>
        </div>
      </div>
      <div id="contact">
        <div>
          <a href="https://form.typeform.com/to/V9GUYYBw" target="_blank">
            <button className="button">문의하기</button>
          </a>
        </div>
      </div>
    </Layout>
  );
};

// Step 3: Export your component
export default AboutPage;
